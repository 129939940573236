import { Button, Modal } from "react-bootstrap";
import "../../Assets/scss/pages/getting-started.scss";
import DashboardBlankPagesTemplate from "../../Template/DashboardBlankPagesTemplate";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import AddNewNormalDomain from "../../Components/AddNewDomainModal/AddNormalDomain";
import AddNewPrePurchasedDomainModalBlock from "../../Components/AddNewDomainModal/AddNewPrePurchasedDomain";
import PaymentButtonBlock from "../../Components/PaymentButton";

import addDomainGD from "../../Assets/img/add-domain-button-gd.svg";
import purchasePlanGD from "../../Assets/img/purchase-plan-gd.svg";
import UpgradePlanList from "../../Components/PaymentButton/UpgradePlanList";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserDetailsService } from "../../Services/UserAction/LoginActionService";
import { useDispatch } from "react-redux";

const AddOnlyDomain = () => {
    return (
        <>
            <div className="add-new-domain-screen-btn-inner">
                <img src={addDomainGD} alt="" />
                <div className="add-new-domain-screen-btn-text">
                    <h1><strong>Add a website domain to <br />kick-start accessibility journey!</strong></h1>
                </div>
            </div>
        </>
    )
}
const AddProPlan = () => {
    return (
        <>
            <div className="add-new-domain-screen-btn-inner">
                <img src={purchasePlanGD} alt="" />
                <div className="add-new-domain-screen-btn-text">
                    <h1>Purchase an All in One Accessibility Pro Plan to <br />kick-start accessibility journey!</h1>
                </div>
            </div>
        </>
    )
}

const GettingStartedPage = () => {

    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const userUpdate = searchParams.get("user_update");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Redux Data
    const { accessToken, user } = useSelector((store: StoreProptypes) => store);

    // State
    const [isAddNewWebsiteModal, setIsAddNewWebsiteModal] = useState<boolean>(false);
    const [isAddNewPrePurchasedWebsiteModal, setIsAddNewPrePurchasedWebsiteModal] = useState<boolean>(false);
    const [isAddMultiStoreWebsiteModal, setIsAddMultiStoreWebsiteModal] = useState<boolean>(false);
    const [isBulkDomainModalContent, setIsBulkDomainModalContent] = useState<boolean>(false);

    // Add Normal Domain Modal
    const handleCloseAddNewWebsiteModal = () => setIsAddNewWebsiteModal(false);
    const handleShowAddNewWebsiteModal = () => setIsAddNewWebsiteModal(true);

    // Pre Purchased Domain Modal
    const handleCloseAddNewPrePurchasedWebsiteModal = () => setIsAddNewPrePurchasedWebsiteModal(false);
    const handleShowAddNewPrePurchasedWebsiteModal = () => setIsAddNewPrePurchasedWebsiteModal(true);

    // Pre Purchased Domain Modal
    const handleCloseAddNewMultiStoreWebsiteModal = () => setIsAddMultiStoreWebsiteModal(false);
    const handleShowAddNewMultiStoreWebsiteModal = () => setIsAddMultiStoreWebsiteModal(true);

    useEffect(() => {
        if(accessToken && userUpdate === 'true') {
            getUserDetailsService(accessToken, dispatch, t).then((userRes) => {
                if(userRes.status === 200) {
                    navigate('/front/getting-started')
                }
            })
        }
    },[userUpdate]);

    useEffect(() => {
        if(user && user.is_extension_user === 1) {
            window.location.href = "/front/edit-profile";
        }
    }, [user])

    return (
        <>
            <DashboardBlankPagesTemplate>
                <div className="aioa_dashboard-getting-started-wrapper">
                    {user && (
                        <>
                            {user.department === 4 ? (
                                <>
                                    {
                                        (user.is_multiple_domain === 1) ? (
                                            <>

                                                <Button variant="link" className="add-new-domain-screen-btn" size="lg" onClick={() => handleShowAddNewPrePurchasedWebsiteModal()}>
                                                    {user.is_multi_domain_purchased === 1 ? (
                                                        <>
                                                            <AddOnlyDomain />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <AddProPlan />
                                                        </>
                                                    )}
                                                </Button>

                                            </>
                                        ) : ( // Normal domain with bulk
                                            <>
                                                <Button variant="link" className="add-new-domain-screen-btn" size="lg" onClick={() => handleShowAddNewWebsiteModal()}>
                                                    <AddOnlyDomain />
                                                </Button>
                                            </>
                                        )
                                    }
                                </>
                            ) : (
                                <>
                                    {user.is_multiple_domain === 2 ? (
                                        <>
                                            <Button variant="link" className="add-new-domain-screen-btn" size="lg" onClick={() => handleShowAddNewMultiStoreWebsiteModal()}>
                                                {user.is_multi_domain_purchased === 1 ? (
                                                    <>
                                                        <AddOnlyDomain />
                                                    </>
                                                ) : (
                                                    <>
                                                        <AddProPlan />
                                                    </>
                                                )}
                                                <div className="aioa_dashboard-btn-icon d-none"><i className="material-symbols-outlined" aria-hidden="true">add</i></div>
                                                <div className="aioa_dashboard-btn-text d-none">{t("Add New Domain")}</div>
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button variant="link" className="add-new-domain-screen-btn" size="lg" onClick={() => handleShowAddNewWebsiteModal()}>
                                                <AddOnlyDomain />
                                            </Button>
                                        </>
                                    )}
                                </>
                            )}

                        </>
                    )}
                </div>

                {/* Normal Domain Add Modal */}
                <Modal show={isAddNewWebsiteModal} onHide={handleCloseAddNewWebsiteModal} size="xl" fullscreen="lg-down" backdrop="static" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t("Add New Domain")}
                            {user && user.department === 4 && (
                            <>
                                <Button variant="link" className="ms-2" onClick={() => setIsBulkDomainModalContent(true)}>{t('bulk domain label')} {t('click here btn label')}</Button>
                            </>
                        )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddNewNormalDomain isBulkDomainModalContent={isBulkDomainModalContent} />
                    </Modal.Body>
                </Modal>

                {/* Pre Purchased Domain Add Modal */}
                <Modal show={isAddNewPrePurchasedWebsiteModal} onHide={handleCloseAddNewPrePurchasedWebsiteModal} size="xl" fullscreen="md-down" backdrop="static" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {user && user.department === 4 && (
                                <>
                                    {user.is_multi_domain_purchased === 1 ? (
                                        <>
                                            {t("Add New Domain")}
                                        </>
                                    ) : (
                                        <>
                                            {t("Select Plan")}
                                        </>
                                    )}
                                </>
                            )}

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {user && user.department === 4 && (
                            <>
                                {user.is_multi_domain_purchased === 1 ? (
                                    <>
                                        <AddNewPrePurchasedDomainModalBlock callBackFunction={handleCloseAddNewPrePurchasedWebsiteModal} />
                                    </>
                                ) : (
                                    <>
                                        <UpgradePlanList
                                            activePlanType= {3} // 1-widget plan, 2- multi domain, 3 - Pre purchase
                                            activePlanInterval= {`Y`}
                                            activePlanPrice= {0}
                                            activePlanDomain= {``}
                                        />

                                    </>
                                )}
                            </>
                        )}
                    </Modal.Body>
                </Modal>

                {/* Pre Purchased Domain Add Modal */}
                <Modal show={isAddMultiStoreWebsiteModal} onHide={handleCloseAddNewMultiStoreWebsiteModal} size="xl" fullscreen="md-down" backdrop="static" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t("Add New Domain")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {user && (
                            <>
                                {user.is_multi_domain_purchased === 1 ? ( // Silver Gold Plan
                                    <>
                                        <AddNewPrePurchasedDomainModalBlock callBackFunction={handleCloseAddNewMultiStoreWebsiteModal} />
                                    </>
                                ) : (
                                    <>
                                        <UpgradePlanList
                                            activePlanType= {2} // 1-widget plan, 2- multi domain, 3 - Pre purchase
                                            activePlanInterval= {`M`}
                                            activePlanPrice= {0}
                                            activePlanDomain= {``}
                                        />

                                    </>
                                )}
                            </>
                        )}

                    </Modal.Body>
                </Modal>

            </DashboardBlankPagesTemplate>
        </>
    )
}

export default GettingStartedPage;
